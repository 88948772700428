import React, { Fragment } from 'react';
import { Button, Container, FlexGrid, Header, Image, Link, Text } from '@gasbuddy/react-components';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { Helmet } from 'react-helmet-async';
import useImageSrcBuilder from '../../../lib/hooks/useImageSrcBuilder';
import styles from './LandingPageTemplate2.module.css';
import { Block, LandingPagePropType } from '../../prop-types/cms-page';
import Page from '../Page';
import Markup from '../Markup';
import generateCMSMetaTags from '../../../lib/utils/generateCMSMetaTags';

const cx = classNames.bind(styles);

function Hero({ blocks }) {
  const [{ description, image }] = blocks;
  const imageToSrc = useImageSrcBuilder();
  const backgroundImage = image && `url(${imageToSrc(image)})`;

  return (
    <div className={cx('hero')} style={{ backgroundImage }}>
      {description && (
        <div className={cx('title')}>
          <Container>
            <Header as="h1">
              <Markup source={description} />
            </Header>
          </Container>
        </div>
      )}
    </div>
  );
}

Hero.propTypes = {
  blocks: PropTypes.arrayOf(Block),
};

Hero.defaultProps = {
  blocks: [],
};

function About({ blocks, imageRight }) {
  const imageToSrc = useImageSrcBuilder();

  return blocks.map(block => (
    <div className={cx('section')} key={block.title || block.description}>
      <Container>
        <FlexGrid container>
          {!imageRight && block.image && (
            <FlexGrid.Column tablet={6} desktop={4}>
              <Image alt={block.alt} className={cx('aboutImageLeft')} fluid src={imageToSrc(block.image)} />
            </FlexGrid.Column>
          )}
          <FlexGrid.Column tablet={block.image ? 6 : 12} desktop={block.image ? 8 : 12}>
            <Header as="h2" className={cx('title')}>{block.title}</Header>
            <Text as="p">
              <Markup source={block.description} />
            </Text>
            {block.ctaLabel && block.ctaUrl && (
              <Button as="a" className={cx('cta')} href={block.ctaUrl} primary>{block.ctaLabel}</Button>
            )}
            {block.ctaDisclaimer && (
              <Text className={cx('ctaDisclaimer')} size="sm">{block.ctaDisclaimer}</Text>
            )}
          </FlexGrid.Column>
          {imageRight && block.image && (
            <FlexGrid.Column tablet={6} desktop={4}>
              <Image alt={block.alt} className={cx('aboutImageRight')} fluid src={imageToSrc(block.image)} />
            </FlexGrid.Column>
          )}
        </FlexGrid>
      </Container>
    </div>
  ));
}

About.propTypes = {
  blocks: PropTypes.arrayOf(Block),
};

About.defaultProps = {
  blocks: [],
};

function BrandShowcase({ blocks, title }) {
  const imageToSrc = useImageSrcBuilder();
  const imageBlocks = blocks.filter(block => !block.customHtml);
  const customHtmlBlock = blocks.find(block => !!block.customHtml);

  return (
    <div className={cx('section')}>
      <Container>
        <Header as="h2" className={cx('title')}>{title}</Header>
        <div className={cx('showcase')}>
          {imageBlocks.map(block => (
            <Link className={cx('item')} key={block.alt || block.image.url} to={block.ctaUrl}>
              <Image alt={block.alt} src={imageToSrc(block.image)} />
            </Link>
          ))}
        </div>
        {!!customHtmlBlock && (
          <div className={cx('listen')}>
            <Markup source={customHtmlBlock.customHtml} />
          </div>
        )}
      </Container>
    </div>
  );
}

BrandShowcase.propTypes = {
  blocks: PropTypes.arrayOf(Block),
  title: PropTypes.string,
};

BrandShowcase.defaultProps = {
  blocks: [],
  title: undefined,
};

function Bios({ blocks, title }) {
  const imageToSrc = useImageSrcBuilder();

  return (
    <div className={cx('section')}>
      <Container>
        {title && (
          <Header as="h2" className={cx('title')}>{title}</Header>
        )}
        <div className={cx('bios')}>
          {blocks.map(block => (
            <FlexGrid className={cx('bio')} key={block.title || block.description}>
              {block.image && (
                <FlexGrid.Column className={cx('imgCol')}>
                  <Image alt={block.alt} src={imageToSrc(block.image)} />
                </FlexGrid.Column>
              )}
              <FlexGrid.Column className={cx('desc')}>
                <Header as="h3" className={cx('title')}>{block.title}</Header>
                <Text as="p">
                  <Markup source={block.description} />
                </Text>
              </FlexGrid.Column>
            </FlexGrid>
          ))}
        </div>
      </Container>
    </div>
  );
}

Bios.propTypes = {
  blocks: PropTypes.arrayOf(Block),
  title: PropTypes.string,
};

Bios.defaultProps = {
  blocks: [],
  title: undefined,
};

function renderSection(section) {
  switch (section.name) {
    case 'hero':
      return <Hero {...section} />;
    case 'about':
      return <About {...section} />;
    case 'aboutImageRight':
      return <About {...section} imageRight />;
    case 'bios':
      return <Bios {...section} />;
    case 'brands':
      return <BrandShowcase {...section} />;
    default:
      return null;
  }
}

function LandingPageTemplate2({ page }) {
  const location = useLocation();
  const imageToSrc = useImageSrcBuilder();
  const attachQueryString = baseUrl => `${baseUrl}${location.search}`;
  const meta = page?.meta?.find(el => el.type === 'meta');

  return (
    <Page
      activeNavKey="blog"
      className={cx('page')}
      compact
      title={page?.title}
      description={meta?.content}
    >
      <Helmet>
        {page?.title && (
          <title>{page.title}</title>
        )}
        {page?.canonicalUrl && (
          <link rel="canonical" href={attachQueryString(page.canonicalUrl)} />
        )}

        {!!page?.meta?.length && generateCMSMetaTags(page.meta, imageToSrc)}
      </Helmet>
      {page?.sectionList?.map(section => (
        <Fragment key={`${section.name}_${section.title}`}>
          {renderSection(section)}
        </Fragment>
      ))}
    </Page>
  );
}

LandingPageTemplate2.propTypes = {
  page: LandingPagePropType,
};

LandingPageTemplate2.defaultProps = {
  page: undefined,
};

export default React.forwardRef(LandingPageTemplate2);
